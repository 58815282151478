.token.punctuation.brace-hover,
.token.punctuation.brace-selected {
	outline: solid 1px;
}

.rainbow-braces .token.punctuation.brace-level-1,
.rainbow-braces .token.punctuation.brace-level-5,
.rainbow-braces .token.punctuation.brace-level-9 {
	color: #E50;
	opacity: 1;
}
.rainbow-braces .token.punctuation.brace-level-2,
.rainbow-braces .token.punctuation.brace-level-6,
.rainbow-braces .token.punctuation.brace-level-10 {
	color: #0B3;
	opacity: 1;
}
.rainbow-braces .token.punctuation.brace-level-3,
.rainbow-braces .token.punctuation.brace-level-7,
.rainbow-braces .token.punctuation.brace-level-11 {
	color: #26F;
	opacity: 1;
}
.rainbow-braces .token.punctuation.brace-level-4,
.rainbow-braces .token.punctuation.brace-level-8,
.rainbow-braces .token.punctuation.brace-level-12 {
	color: #E0E;
	opacity: 1;
}
